document.addEventListener('DOMContentLoaded', function(){
  // pagetop
  pagetop();
  // accordion
  accordion( 'js-accordion-trigger' );
  // tab
  tab();
  // 電話タップ
  telLink();
  // ページ内リンク
  pageAnchor();

  // スクロールバーの装飾
  $('nav').mCustomScrollbar({ theme: 'minimal-dark' });

  // メニュー開閉イベント
  mainContents.addEventListener('click', function( event ){
    if ( ! matches( event.target, burgerObj ) && $('body').hasClass( openMenuClass ) ) {
      document.body.classList.remove( openMenuClass );
    } else if ( matches( event.target, burgerObj ) ) {
      document.body.classList.toggle( openMenuClass );
    }
  });

  // メニュー 子メニューの開閉イベント
  $( childTrigger ).children('a').on('click', function( event ){
    event.preventDefault();
    $(this).toggleClass('child-menu-open')
      .next('.side-navigation-child').slideToggle(200)
      .end().parent(childTrigger).siblings(childTrigger).children('a').removeClass('child-menu-open')
      .next('.side-navigation-child').slideUp(200);
  });

  // disabled button のイベント回避
  const btn = document.querySelectorAll('.button');
  if( btn.length ){
    btn.forEach( elm => {
      elm.addEventListener('click', function(e){
        if( this.classList.contains('disabled') ){
          e.preventDefault();
        }
      });
    });
  };

  // アンカーリンクでページ遷移したときの処理
  const location = window.location.hash;
  if( location ){
    const pageID = document.getElementById( location.slice(1) );
    if( !pageID ){ return; };
    const rect = pageID.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let position = rect.top + scrollTop - 16;
    // header の高さ
    const headerHeight = header.clientHeight;
    // スマホ タブレットの場合
    if( mediaQuerySm.matches || mediaQueryMd.matches ){
      position = position - headerHeight;
    }
    //スクロール処理
      $('body,html').animate({
        scrollTop: position
      }, 400, 'swing' );
  };


});

// IE滅亡
const ieAlert = document.getElementById('ieAlert');
const ieAlertClose = document.getElementById('ieAlertClose');
if( ieAlert !== null ){
  ieAlertClose.addEventListener('click', function(e){
    ieAlert.classList.add('invisible');
  });
};


/** Variables */

// バーガーメニュー
const burgerObj = '#js-burger, .burger-icon, #js-burger span';
// メニューオープン時につける class
const openMenuClass = 'visible-side-nav';
// navigation Child Menu
const childTrigger = '.is-child-menu';
// メインコンテンツ領域
const mainContents = document.getElementById( 'mainContents' );
// header
const header = document.getElementById( 'header' );
// メディアクエリ
const mediaQuerySm = window.matchMedia( '(max-width: 767px)' );
const mediaQueryMd = window.matchMedia( '(max-width: 991px)' );
const mediaQuerylg = window.matchMedia( '(max-width: 1199px)' );



/**
***  jQuery .is() -> JavaScript  */
const matches = function( el, selector ) {
  return ( el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector ).call( el, selector );
};



/**
***  pagetop  */
const pagetop = () => {
  const pagetop = document.getElementById('js-pagetop');
  const pagetopActiveClass = 'active';

  if ( pagetop != null ) {
    window.addEventListener('scroll', function(e) {
      if (window.scrollY > 100) {
        pagetop.classList.add(pagetopActiveClass);
      } else {
        pagetop.classList.remove(pagetopActiveClass);
      };
    });
    pagetop.addEventListener('click', function(){
      $('body, html').animate({ scrollTop: 0 }, 400);
    }, false);
  };
};



/**
***  accordion  */
const accordion = ( triggerClassName ) => {
  // Cross-browser way to get the computed height of a certain element.
  const getStyle = (el, styleProp) => {
    let value, defaultView = ( el.ownerDocument || document ).defaultView;
    // W3C standard way:
    if ( defaultView && defaultView.getComputedStyle ) {
      // sanitize property name to css notation
      // (hypen separated words eg. font-Size)
      styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();
      return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
    } else if ( el.currentStyle ) { // IE
      // sanitize property name to camelCase
      styleProp = styleProp.replace(/\-(\w)/g, function(str, letter) {
        return letter.toUpperCase();
      });
      value = el.currentStyle[styleProp];
      // convert other units to pixels on IE
      if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
        return (function(value) {
          var oldLeft = el.style.left, oldRsLeft = el.runtimeStyle.left;
          el.runtimeStyle.left = el.currentStyle.left;
          el.style.left = value || 0;
          value = el.style.pixelLeft + "px";
          el.style.left = oldLeft;
          el.runtimeStyle.left = oldRsLeft;
          return value;
        })(value);
      };
      return value;
    };
  };
  // アコーディオン処理
  const acc = document.getElementsByClassName(triggerClassName);
  const activeClassName = 'active';
  let i;
  // ページを開いた時にアコーディオンを開いた状態にしたいときの処理
  //const firstAccordion = acc[0];
  //const firstPanel = firstAccordion.nextElementSibling;
  //firstAccordion.classList.add(activeClassName);
  //firstPanel.style.maxHeight = firstPanel.scrollHeight + 'px';

  // Add onclick listener to every accordion element
  for ( i = 0; i < acc.length; i++ ) {
    acc[i].addEventListener('click', function() {
      // For toggling purposes detect if the clicked section is already 'active'
      const isActive = this.classList.contains(activeClassName);
      // Close all accordions
      const allAccordions = document.getElementsByClassName(triggerClassName);
      for ( let j = 0; j < allAccordions.length; j++) {
        // Remove active class from section header
        allAccordions[j].classList.remove(activeClassName);
        // Remove the max-height class from the panel to close it
        const panel = allAccordions[j].nextElementSibling;
        const maxHeightValue = getStyle(panel, 'maxHeight');

        if (maxHeightValue !== '0px') {
          panel.style.maxHeight = null;
        };
      };
      // Toggle the clicked section using a ternary operator
      isActive ? this.classList.remove(activeClassName) : this.classList.add(activeClassName);
      // Toggle the panel element
      const panel = this.nextElementSibling;
      const maxHeightValue = getStyle( panel, 'maxHeight' );
      if (maxHeightValue !== '0px') {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      };
    });
  };
};



/**
***  tab  */
const tab = () => {
  const tabs = document.getElementsByClassName('js-tab');
  const allContents = document.getElementsByClassName('js-contents');
  const currentClassName = 'current';
  const currentViewClassName = 'current-view';
  //
  for (let i = 0; i < tabs.length; i++){
    tabs[i].addEventListener('click', function(){

      // データ属性を取得する
      const view = this.dataset.view;
      // .current が付与しているかチェック
      const isCurrent = this.classList.contains(currentClassName);

      if (!isCurrent) {
        // remove .current
        for ( let j = 0; j < tabs.length; j++ ){
          tabs[j].classList.remove(currentClassName);
        };
        this.classList.add(currentClassName);
      };

      // remove .current-view
      for ( let k = 0; k < allContents.length; k++ ){
        allContents[k].classList.remove(currentViewClassName);
      };
      //
      document.getElementById(view).classList.add(currentViewClassName);
    });
  };
};



/** タップで電話 */
/** telLink */
const telLink = function() {
  const ua = navigator.userAgent;
  if( ua.match(/iPhone|Android.+Mobile/) ){
    return;
  } else {
    const telLink = document.querySelectorAll('.js-tel-link');
    if( telLink.length ){
      telLink.forEach( l => {
        l.addEventListener('click', (e) => {
          e.preventDefault();
        });
      });
    }
  }
};



// ページ内リンク
const pageAnchor = function(){
  const elm = document.querySelectorAll('.js-page-anchor');

  // ない場合はなにもしない
  if( ! elm.length ){ return; };

  // イベント
  for ( let i = 0; i < elm.length; i++) {
    elm[i].addEventListener( 'click', function(e){
      e.preventDefault();
      const href = this.getAttribute('href');
      // ページ内リンクでない場合は何もしない
      if( href.slice(0, 1) != '#' ){
        return;
      };

      // リンク先の要素を取得
      const pageID = document.getElementById(href.slice(1));
      if( !pageID ){ return; };
      const rect = pageID.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let position = rect.top + scrollTop - 16;

      // header の高さ
      const headerHeight = header.clientHeight;

      // スマホ タブレットの場合
      if( mediaQuerySm.matches || mediaQueryMd.matches ){
        position = position - headerHeight;
      }

      //スクロール処理
      $('body,html').animate({
        scrollTop: position
      }, 400, 'swing' );
    });
  }
};
